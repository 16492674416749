import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { OrderService } from '../../order.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { startWith, map, filter } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { message } from 'src/app/shared/constants/alerts_messages';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionPermissions } from 'src/app/shared/constants/permissions';
import { generalErrorMessage } from 'src/app/shared/errors/error-messages';
import { SharedService } from 'src/app/shared/shared.service';
import { th } from 'date-fns/locale';

@Component({
  selector: 'app-order-reviewer-assignment',
  templateUrl: './order-reviewer-assignment.component.html',
  styleUrls: ['./order-reviewer-assignment.component.scss']
})
export class OrderReviewerAssignmentComponent {
  @ViewChild(MatAutocompleteTrigger) auto!: MatAutocompleteTrigger;
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger })
  reviewerList: any = [];
  subStatusList: any = [];
  filteredReviewers: any[] = [];
  filteredSubStatus: any[] = [];
  reviewerCtrl = new FormControl();
  statusCtrl = new FormControl();
  msgCtrl = new FormControl();
  title: string;
  permission = ActionPermissions;
  selectedUserId: string;

   subStatusFilters = {
    page_size: 50,
    page_number: 1,
    sort_order: 'desc',
    sort_by: 'created_on',
    search: '',
    tier_id: ''
  };

  constructor(
    public dialogRef: MatDialogRef<OrderReviewerAssignmentComponent>,
    private orderService: OrderService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService, private route: ActivatedRoute,
    private sharedService: SharedService
  ) {
    this.title = data.title;
  }

  ngOnInit(): void {
    if ( this.title === 'Reviewers List') {
      this.getReviewers();
    }
    if (this.title === 'Escalate To') {
    this.getsubStatus();
    }
    window.addEventListener('scroll', this.scrollEvent, true);
  }

  scrollEvent = (event: any): void => {
    if ((event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) && (this.subStatusList?.length % 50 === 0)) {
      this.subStatusFilters.page_number++;
      this.sharedService.getOrderSubStatus(this.subStatusFilters).subscribe((substatus: any) => {
        this.subStatusList = this.subStatusList.concat(substatus.data);
        this.filteredSubStatus = this.subStatusList;
      });
    }
  };

  getsubStatus(): void {
    this.sharedService.getOrderSubStatus(this.subStatusFilters).subscribe((substatus: any) => {
      this.subStatusList = substatus.data;
      this.filteredSubStatus = this.subStatusList;
    });
  }

  filterSubStatusOptions(value: string, event: KeyboardEvent) {
    this.subStatusFilters.search = value.toLowerCase().trim();
    this.msgCtrl.setValue('');
    if (event.key === 'Enter') {
      this.subStatusFilters.page_number = 1;
      this.getsubStatus();
    }
  }

  displayName( substatus: any) {
    const result = this.subStatusList?.find((status:any) => status.notification_title === substatus.notification_title);
    this.msgCtrl.setValue(result ? `${result.notification}` : '');
    return result ? `${result.notification_title}` : '';
  }

  showStatusError(): boolean {
    const selectedName = this.statusCtrl.value?.notification_title ? this.statusCtrl.value.notification_title : this.statusCtrl.value;
    return (
      selectedName &&
      !this.subStatusList.some(
        (status: any) =>
          status.notification_title === selectedName
      )
    );
  }

  getReviewers(): void {
    this.orderService.getPiolotUsers()?.then((users: any) => {
      this.reviewerList = [...users.Admins, ... users.Managers, ...users.Reviewers];
      this.filteredReviewers = this.reviewerList;
      this.setupReviewerFilter();
    });
  }

  setupReviewerFilter(): void {
    this.reviewerCtrl.valueChanges
      .pipe(startWith(''), map((value) => this.filterReviewers(value)))
      .subscribe((filteredReviewers) => {
        this.filteredReviewers = filteredReviewers;
      });
  }

  filterReviewers(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.reviewerList
      ?.filter(
        (reviewer: any) =>
          reviewer.first_name.toLowerCase().includes(filterValue) ||
          reviewer.last_name?.toLowerCase().includes(filterValue)
      )
      .map((reviewer: any) => `${reviewer.first_name} ${reviewer.last_name ? reviewer.last_name : ''}`);
  }

  onSelection(event: any, formControlName: any): void {
    const selectedName = event.option.value;
    formControlName.setValue(selectedName);
    const selectedReviewerName = this.reviewerCtrl.value;
    const selectedReviewer = this.reviewerList?.find(
      (reviewer: any) =>
        `${reviewer.first_name} ${reviewer.last_name ? reviewer.last_name : ''}` === selectedReviewerName
    );
    this.selectedUserId = selectedReviewer ? selectedReviewer.external_id : '';
  }

  updateReviewer(): void {
    const requestBody = {
      order_number: this.data.orderNumber,
      review_id: this.data.reviewId,
      reviewer_uid: this.selectedUserId,
      message: this.msgCtrl.value
    };
    this.dialogRef.close(requestBody);
  }

  escalate(): void {
    const requestBody = {
      substatus_id: this.statusCtrl.value.id,
      message: this.msgCtrl.value
    };
    this.dialogRef.close(requestBody);
  }

  updateBulkEscalate(): void {
    const requestBody = {
      substatus_id: this.statusCtrl.value.id,
      message: this.msgCtrl.value
    };
    this.orderService.escalateReview(requestBody, this.data.orderIds)?.subscribe(
      (response) => {
        if (response) {
          this.toastr.success('Orders Escalated Successfully.');
          this.dialogRef.close(response);
        }
      },
      (error) => {
        this.toastr.error(error?.error?.detail && !Array.isArray(error.error.detail) ? error?.error?.detail : generalErrorMessage);
      }
    );
  }

  updateOrdersReviewerInBulk(): void {
    const requestBody = {
      orderIds: this.data.orderIds,
      reviewerId: this.selectedUserId,
      message: this.msgCtrl.value
    };
    this.orderService.updateReviewerInBulk(requestBody)?.subscribe(
      (response) => {
        if (response) {
          this.toastr.success(message.updateReviewerAssignment);
          this.dialogRef.close(response);
        }
      },
      (error) => {
        this.toastr.error(error?.error?.detail && !Array.isArray(error.error.detail) ? error?.error?.detail : generalErrorMessage);
      }
    );
  }

  performBulkAction(): void {
    this.title === 'Escalate To' ? this.updateBulkEscalate() : this.updateOrdersReviewerInBulk();
  }

  saveReviewAssignment(): void {
   this.title === 'Escalate To' ?  this.escalate() : this.updateReviewer();
  }

  showReviewerError(): boolean {
    const selectedReviewerName = this.reviewerCtrl.value;
    return (
      selectedReviewerName &&
      !this.reviewerList.some(
        (reviewer: any) =>
          `${reviewer.first_name} ${reviewer.last_name ? reviewer.last_name : ''}` === selectedReviewerName
      )
    );
  }

  cancelMessage(): void {
    if (this.title === 'A message for reviewer') {
      this.updateReviewer();
    } else {
      this.dialogRef.close();
    }
  }

}
