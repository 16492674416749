<div class="order-review-wrapper" [ngClass]="{'order-review-header': page !== 'edit-order'}" *ngIf="!revisionRequested">
  <div class="order-review-header__row" *ngIf="page === 'edit-order'">

    <div class="order-review-header__col">
      <div class="order-review-header__head-wrapper">
        <div class="order-review-header__switch-wrapper">
          <label class="switch">
            <input type="checkbox" [(ngModel)]="toggleState" [disabled]="!formHtml" (change)="changePdfDisplayState($event)">
            <span class="slider round"></span>
          </label>
          <small>Show PDF Document</small>
        </div>
        <div class="order-review-header__action-dropdown" (click)="orderInfoCollapse.emit()">
          <app-svg [svgPath]="'../../../../assets/images/info.svg'"></app-svg>
        </div>
        <div class="order-review-header__action-dropdown" (click)="openLayoutSelectionDialog()">
          <app-svg [svgPath]="'../../../../assets/images/layout.svg'"></app-svg>
        </div>
        <div class="order-review-header__action-dropdown">
          <span [matMenuTriggerFor]="actionMenu"><mat-icon>more_vert</mat-icon></span>
          <mat-menu color="primary" #actionMenu="matMenu">
            <button mat-menu-item [disabled]="resolvedFindings !== totalFindings" *ngIf="token" (click)="updateOrderReviewStatus(orderStatuses.PENDING_REVIEW, 'PENDING_REVIEW', false, toastMsgs.orderReviewSubmitted)">Submit</button>
            <button mat-menu-item *ngIf="!token" (click)="navigateToPreview()">View PDF Document</button>
            <button mat-menu-item [disabled]="rejectedfindings === 0 || isPreview || vendorAndReviewer && !vendorAndReviewer?.assigned_to_reviewer" *ngIf="!token && userConfig.checkPermissions(permission.REQUEST_FOR_REVISION) && vendorAndReviewer && !vendorAndReviewer?.assigned_to_vendor" (click)="confirmActionRequestForRevision(orderStatuses.REVISION_REQUESTED, 'REVISION_REQUESTED', false, toastMsgs.revisionRequested)">Request for Revision</button>
            <button mat-menu-item [disabled]="rejectedfindings === 0 || isPreview || vendorAndReviewer && !vendorAndReviewer?.assigned_to_reviewer" *ngIf="!token && userConfig.checkPermissions(permission.REQUEST_FOR_REVISION) && vendorAndReviewer && vendorAndReviewer?.assigned_to_vendor" (click)="backToReport.emit()">Request for Revision</button>
            <button mat-menu-item [disabled]="rejectedfindings > 0 || resolvedFindings !== totalFindings || isPreview" *ngIf="!token && userConfig.checkPermissions(permission.MARK_AS_COMPLETE)" (click)="updateOrderReviewStatus(orderStatuses.COMPLETED, 'COMPLETED', true, toastMsgs.markedAsCompletedReview)">Mark as Complete</button>
            <button mat-menu-item [disabled]="isPreview" *ngIf="isEADactive" (click)="submitOrderToEAD()">Submit to EAD</button>
            <button mat-menu-item [disabled]="isPreview" *ngIf="isEADactive && isEADsubmitted" (click)="openOverrideSubmissionModal('EAD')">EAD Override Submission</button>
            <button mat-menu-item [disabled]="isPreview" *ngIf="isUCDPactive" (click)="submitOrderToUCDP()">Submit to UCDP</button>
            <button mat-menu-item [disabled]="isPreview" *ngIf="isUCDPactive && isUCDPsubmitted" (click)="openOverrideSubmissionModal('UCDP')">UCDP Override Submission</button>
            <button mat-menu-item [disabled]="isPreview" *ngIf="(isUCDPactive || isEADactive) && (eadAppraisalNumber && eadAppraisalNumber !== appraisalNumber.APPRAISAL_ONE) || (ucdpAppraisalNumber && ucdpAppraisalNumber !== appraisalNumber.APPRAISAL_ONE)" (click)="deleteAppraisal()">Delete Appraisal</button>
            <button mat-menu-item [disabled]="isPreview" *ngIf="!token && userConfig.checkPermissions(permission.ASSIGN_MYSELF)" (click)="assignToMyself()">Assign Myself</button>
            <button mat-menu-item *ngIf="!token && userConfig.checkPermissions(permission.ARCHIVE_ORDER)" [disabled]="orderStatus !== (orderStatuses.COMPLETED || isPreview)"
             (click)="archiveOrderDialog()">Archive Order</button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>

  <div class="order-review-header__row" *ngIf="page === 'preview'">
    <div class="order-review-header__col">
      <div class="order-review-header__order-number">
        <div>
          <h5>Preview Order ({{orderNo}}) <app-order-status [orderStatus]="orderStatus"></app-order-status> </h5>
        </div>
      </div>
    </div>
    <div class="order-review-header__col">
      <div class="order-review-header__order-number">
        <p>Revision {{revisionNumber}}</p>
      </div>
    </div>
  </div>
</div>
