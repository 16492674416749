import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { message } from 'src/app/shared/constants/alerts_messages';
import { USPSVerification } from 'src/app/shared/enums';
import { Client } from '../../orders.interface';
import { UserConfigurationService } from 'src/app/core/services/user-configuration.service';
import { ActionPermissions } from 'src/app/shared/constants';

@Component({
  selector: 'app-pdf-view',
  templateUrl: './pdf-view.component.html',
  styleUrls: ['./pdf-view.component.scss']
})
export class PdfViewComponent {
  @Input() clientDetail: Client;
  @Input() clientOnReportDetail: Client;
  @Input() verifiedAddress: string;
  @Input() expendInfo: boolean;
  @Input() stepForRevision = 0;
  @Input() orderReviewHeaderData = {
    client: '',
    clientAddress: '',
    clientOnReport: '',
    clientOnReportAddress: '',
    borrowerName: '',
    loanType: '',
    fhaCaseNo: '' as string | null,
    purposeType: '',
    isEADactive: false,
    isUCDPactive: false,
    reviewer: '' as string | null,
    appraiser: '' as string | null,
    createdDate: '',
    dueDate: '' as string | null,
    revisionNo: 0,
    address: '',
    vendor: '' as string | null,
    product: '' as string | null
  };
  
  permission = ActionPermissions;
  addressMsg = '';
  addressMsgImg = '../../../../assets/images/check-circle.svg';
  activeSection = 1;

  maxWidth = 1050;
  isTabletView = false;

  constructor (public userConfig: UserConfigurationService) {
  }

  ngOnChanges () {
    if (this.verifiedAddress === USPSVerification.VERIFIED) {
      this.addressMsg = message.addressVerified;
      this.addressMsgImg = '../../../../assets/images/check-circle.svg';
    } else if (this.verifiedAddress === USPSVerification.NOT_VERIFIED) {
      this.addressMsg = message.addressNotVerified;
      this.addressMsgImg = '../../../../assets/images/warning.png';
    }

    const width = window.innerWidth;
    if (width <= this.maxWidth) {
      this.isTabletView = true
    }
  }

  @ViewChild('container') containerRef: ElementRef;
  @ViewChild('element1') element1Ref: ElementRef;
  @ViewChild('element2') element2Ref: ElementRef;
  @ViewChild('element3') element3Ref: ElementRef;
  @ViewChild('element4') element4Ref: ElementRef;

  scrollToElement(index: number) {
    const container = this.containerRef.nativeElement as HTMLElement;

    let targetElement: HTMLElement;
    switch (index) {
      case 1:
        targetElement = this.element1Ref.nativeElement;
        break;
      case 2:
        targetElement = this.element2Ref.nativeElement;
        break;
      case 3:
        targetElement = this.element3Ref.nativeElement;
        break;
      case 4:
        targetElement = this.element4Ref.nativeElement;
        break;
      default:
        return;
    }
    container.scrollTo({
      top: targetElement.offsetTop - 35,
      behavior: 'smooth'
    });
    this.activeSection = index;
  }

  detectActiveSection() {
    const container = this.containerRef.nativeElement as HTMLElement;
    const scrollPosition = container.scrollTop;
    const containerTop = container.offsetTop;

    const elements = [
      this.element1Ref.nativeElement as HTMLElement,
      this.element2Ref.nativeElement as HTMLElement,
      this.element3Ref.nativeElement as HTMLElement,
      this.element4Ref.nativeElement as HTMLElement
    ];

    let activeIndex = -1;
    let minDistance = Number.MAX_VALUE;

    elements.forEach((element, index) => {
      const elementTop = element.offsetTop - containerTop;
      const distance = Math.abs(scrollPosition - elementTop);
      if (distance < minDistance) {
        minDistance = distance;
        activeIndex = index + 1;
      }
    });

    this.activeSection = activeIndex;
  }

}
