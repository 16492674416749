import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { requiredErrorMessage } from 'src/app/shared/errors/error-messages';
import { OrderService } from '../../order.service';
import { SharedService } from 'src/app/shared/shared.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-add-findings',
  templateUrl: './add-findings.component.html',
  styleUrls: ['./add-findings.component.scss']
})
export class AddFindingsComponent {
  addFindingsForm: FormGroup;
  requiredFieldMsg = requiredErrorMessage;

  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger })

  groupList: any = [];
  filteredGroups: any[] = [];
  mfgId: string;

  filters = {
    page_size: 10,
    page: 1,
    descending: true,
    sort_field: 'created_on',
    search_term: '',
    status: 'active',
    count: false,
  };

  submitted = false;

  @Input() reviewId: string;
  @Output() closeFindingBox = new EventEmitter<boolean>();


  constructor(
    private orderService: OrderService,
    public sharedService: SharedService,
    ) {
    this.addFindingsForm = new FormGroup({
      status: new FormControl('', [Validators.required]),
      finding_text1: new FormControl('', [Validators.required]),
      comment: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.getManualFindingGroups();
    window.addEventListener('scroll', this.scrollEvent, true);

  }

  scrollEvent = (event: any): void => {
    if ((event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) && (this.groupList?.length % 10 === 0)) {
      this.filters.page++;
      this.sharedService.fetchAllRulesForRuleEngine('mfg/', this.filters).subscribe((group) => {
        this.groupList = this.groupList.concat(group.data);
        this.filteredGroups = this.groupList;
      })
    }
  };

  getManualFindingGroups(): void {
    this.sharedService.fetchAllRulesForRuleEngine('mfg/', this.filters)?.subscribe((groups) => {
      this.groupList = groups.data;
      this.filteredGroups = this.groupList;
    });
  }

  filterManualFindingGroupOptions(value: string, event: KeyboardEvent) {
    this.filters.search_term = value.toLowerCase().trim();
    if (event.key === 'Enter') {
      this.filters.page = 1;

      this.getManualFindingGroups();
    }

  }

  showGroupNotFoundError(): boolean {
    const selectedGroup = this.addFindingsForm.get('finding_text1')?.value;
    return (
      selectedGroup &&
      !this.groupList.some((group: any) => group.description === selectedGroup)
    );
  }

  displayGroupName(groupDescription: string) {
    const result = this.groupList?.find((group:any) => group.description === groupDescription);
    this.mfgId =  result ? result.id : '';
    return result ? `${result.mfg_code}: ${result.description.replaceAll('\n', ' ')}`: '';
  }

  onSubmit(): void {
    const payload = {
      finding_text1: this.addFindingsForm.get('finding_text1')?.value.trim(),
      review_id: this.reviewId,
      status: this.addFindingsForm.get('status')?.value,
      comment: this.addFindingsForm.get('comment')?.value.trim(),
      mfg_id: this.mfgId
    }
    if (this.submitted) {
      return;
    }
    this.submitted = true;

    this.orderService.addManualFinding(payload).subscribe(
      () => {
        this.submitted = false;
        this.closeFindingBox.emit(true);
      }
    );
  }

}
