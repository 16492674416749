<div class="findings-view__search-wrap" [ngClass]="{ 'checkbox-selected': isAnyCheckboxSelected }">
  <div
    class="findings-view__status-bar"
    [ngClass]="{ 'bulk-active': isBulkStatusChanges }"
    *ngIf="isAnyCheckboxSelected"
  >
    <div class="selected-state">
      <mat-checkbox
        *ngIf="!isPreview"
        (change)="$event ? toggleAllRows() : null; onAllCheckboxChange($event)"
        [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
        [aria-label]="checkboxLabel()"
        [disabled]="isPreview"
        [color]="'#fff'"
      >
      </mat-checkbox>
      <div class="selected-count">
        <div *ngIf="!isBulkStatusChanges">
          <app-svg
            *ngIf="!token"
            [svgPath]="'../../../../assets/images/x.svg'"
            (click)="clearCheckboxSelections()"
          ></app-svg>
        </div>
        <span *ngIf="!isBulkStatusChanges">({{ this.selection.selected.length }})Selected</span>
      </div>
    </div>
    <div class="status-main">
      <div class="status-inner" (click)="changeStatusInBulk()">
        <app-svg *ngIf="!token" [svgPath]="'../../../../assets/images/edit-2.svg'"></app-svg>
        <span>Change Status</span>
      </div>
    </div>
    <div class="icon-box" *ngIf="isBulkStatusChanges">
      <div class="approved finding-approve-active">
        <app-svg
          *ngIf="!token"
          [svgPath]="'../../../../assets/images/noun-check.svg'"
          title="Approve"
          (click)="applyBulkAction('Approved')"
          [ngClass]="{
            'approved inactive-btn':
              addBulkActionStatus !== 'Approved' && addBulkActionStatus !== '',
            'finding-approve-active':
              addBulkActionStatus === 'Approved' || addBulkActionStatus !== ''
          }"
        ></app-svg>
      </div>
      <div class="reject finding-reject-active">
        <app-svg
          *ngIf="!token"
          [svgPath]="'../../../../assets/images/noun-reject.svg'"
          title="Reject"
          (click)="applyBulkAction('Rejected')"
          [ngClass]="{
            'reject inactive-btn': addBulkActionStatus !== 'Rejected' && addBulkActionStatus !== '',
            'finding-approve-active':
              addBulkActionStatus === 'Rejected' || addBulkActionStatus !== ''
          }"
        ></app-svg>
      </div>
      <div class="not-applicable finding-applicable-active">
        <app-svg
          *ngIf="!token"
          [svgPath]="'../../../../assets/images/noun-applicable.svg'"
          title="Not Applicable"
          (click)="applyBulkAction('Not Applicable')"
          [ngClass]="{
            'not-applicable inactive-btn':
              addBulkActionStatus !== 'Not Applicable' && addBulkActionStatus !== '',
            'finding-approve-active':
              addBulkActionStatus === 'Not Applicable' || addBulkActionStatus !== ''
          }"
        ></app-svg>
      </div>
    </div>
  </div>
  <div class="findings-view__input-box" *ngIf="toggleSearch && !isAnyCheckboxSelected">
    <input
      type="text"
      class="findings-view__input"
      [disabled]="isPreview"
      placeholder="Search findings"
      [(ngModel)]="searchTerm"
      (keyup.enter)="searchFindings()"
    />
    <mat-icon
      fontSet="material-icons-outlined"
      [class.disabled]="isPreview"
      (click)="searchFindings()"
      >search</mat-icon
    >
  </div>
  <button
    class="findings-view__close-btn"
    title="Close Finding"
    [disabled]="isPreview"
    (click)="toggleSearch = !toggleSearch; searchFindings(true)"
    *ngIf="toggleSearch"
  >
    <mat-icon>close</mat-icon>
  </button>
  <div class="findings-view__count-box" *ngIf="!isAnyCheckboxSelected && !revisionRequested">
    <div class="findings-view__count-item" *ngIf="!toggleSearch">
      <div [ngClass]="{ disabled: isPreview }" *ngIf="findingsFormArray.length">
        <mat-checkbox
          (change)="$event ? toggleAllRows() : null; onAllCheckboxChange($event)"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
          [disabled]="isPreview"
          [color]="'#fff'"
        >
        </mat-checkbox>
        <span>Select All</span>
      </div>
      <div>
        <strong>Count:</strong> {{ filteredFindings ? filteredFindings.length : 0 }}
        {{ filteredFindings && filteredFindings.length === 1 ? 'record' : 'records' }}
      </div>
    </div>
    <div class="findings-view__btn-wrapper">
      <button
        class="findings-view__search-btn"
        title="Search Finding"
        [disabled]="isPreview"
        (click)="toggleSearchBar()"
        *ngIf="!toggleSearch"
      >
        <mat-icon>search</mat-icon>
      </button>
      <button
        *ngIf="!token && !toggleSearch"
        [disabled]="isPreview"
        (click)="toggleAddFindingBox()"
        class="findings-view__search-btn"
        [ngClass]="{ selected: toggleManualFinding }"
      >
        <app-svg
          [svgPath]="'../../../../assets/images/plus-circle.svg'"
          title="Add Finding"
        ></app-svg>
      </button>
      <button
        [disabled]="token || isPreview"
        class="findings-view__search-btn"
        *ngIf="!toggleSearch"
        (click)="toggleFilter()"
        [ngClass]="{ selected: isFilterExpanded }"
      >
        <span *ngIf="isFilterApplied" class="badge">{{
          previousFilterTypes.length + previousFilterStatus.length
        }}</span>
        <app-svg
          [svgPath]="'../../../../assets/images/sort-variant.svg'"
          title="Filter Findings"
        ></app-svg>
      </button>
    </div>
  </div>
  <div class="change-status" *ngIf="isBulkStatusChanges">
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>Add Comment</mat-label>
      <textarea
        matInput
        rows="3"
        placeholder="Add your comment here"
        class="findings-view__textareaaaa"
        [formControl]="addBulkActionComment"
        (focusout)="sharedService.removeSpaces(addBulkActionComment)"
      ></textarea>
    </mat-form-field>
    <div class="btns-wrap" *ngIf="isBulkStatusChanges">
      <button class="button-outline" (click)="cancelBulkAction()">Cancel</button>
      <button
        class="btn-filled"
        type="submit"
        [disabled]="!addBulkActionComment.valid || addBulkActionStatus === ''"
        (click)="resolveFindingsInBulk()"
      >
        Confirm
      </button>
    </div>
  </div>
</div>
<div *ngIf="toggleManualFinding">
  <app-add-findings
    [reviewId]="reviewId"
    (closeFindingBox)="closeFindingBox($event)"
  ></app-add-findings>
</div>
<div class="findings-view" id="findingContainer" [ngClass]="{'setDynamicHeight': !userConfig.checkPermissions(permission.ESCALATE_TO) || revisionRequested}" >
  <div class="findings-view__applied-filters" *ngIf="isFilterApplied && !isFilterExpanded">
    <h5>Applied Filters:</h5>
    <mat-chip *ngIf="previousFilterTypes.length" [title]="previousFilterTypes.join(', ')"
      >{{ previousFilterTypes.length }}
      {{ previousFilterTypes.length === 1 ? 'Finding Type' : 'Finding Types'
      }}<mat-icon matChipRemove (click)="removeFilters(false)">cancel</mat-icon></mat-chip
    >
    <mat-chip *ngIf="previousFilterStatus.length" [title]="previousFilterStatus.join(', ')"
      >{{ previousFilterStatus.length }}
      {{ previousFilterStatus.length === 1 ? 'Status Type' : 'Status Types'
      }}<mat-icon matChipRemove (click)="removeFilters(true)">cancel</mat-icon></mat-chip
    >
  </div>
  <div class="filter-wrapper" *ngIf="isFilterExpanded">
    <div class="filter-wrapper__inner">
      <h5>Finding Type</h5>
      <div class="filter-wrapper__filter">
        <div *ngFor="let source of nameOfFindingsSource">
          <app-order-status
            [textToDisplay]="source"
            (click)="selectTypeFilter(source)"
            [class.activeFilter]="selectedTypes.includes(source)"
          ></app-order-status>
        </div>
      </div>
      <h5>Status Type</h5>
      <div class="filter-wrapper__filter">
        <div *ngFor="let status of namesOfFindingsStatuses">
          <app-order-status
            [textToDisplay]="status"
            (click)="selectStatusFilter(status)"
            [class.activeFilter]="selectedStatuses.includes(status)"
          ></app-order-status>
        </div>
      </div>
    </div>
    <div class="filter-wrapper__btn-wrapper">
      <button class="btn-outline-disabled" (click)="toggleFilter()">Cancel</button>
      <button class="btn-filled" (click)="applyFilter()">Confirm</button>
    </div>
  </div>

  <div *ngIf="!isFilterExpanded" cdkDropList (cdkDropListDropped)="drop($event)" [ngClass]="{'revision-list': revisionRequested}">
    <div *ngFor="let finding of filteredFindings; let i = index">
      <div
        class="findings-view__card"
        [ngClass]="{'revision-box': revisionRequested}"
        cdkDrag
        [cdkDragDisabled]="!revisionRequested"
        [class.findings-view__card--disabled]="isPreview"
        (click)="focusFinding(finding)"
        [ngClass]="{ selected: selection.isSelected(finding) }"
        [attr.finding-xpath]="finding.xpath"
        (mouseenter)="hoveredFindingIndex = i"
        (mouseleave)="hoveredFindingIndex = null"
      >
        <form [formGroup]="findingsFormArray[i]">
          <div class="findings-view__card-header">
            <div class="card-checkbox-wrap">
              <mat-checkbox
                *ngIf="(isAnyCheckboxSelected || hoveredFindingIndex === i) && !isPreview && !revisionRequested"
                (click)="$event.stopPropagation()"
                (change)="
                  $event ? selection.toggle(finding) : null; onCheckboxChange($event, finding)
                "
                [checked]="selection.isSelected(finding)"
                [aria-label]="checkboxLabel(finding)"
                [color]="'#fff'"
              >
              </mat-checkbox>
              <div class="chip-wrap">
                <h6>
                  {{ finding?.review_finding_type?.name }}
                </h6>
              </div>
            </div>
            <div class="findings-view__icon-container">
              <mat-icon
                *ngIf="finding.review_finding_type.name == 'MF'"
                fontSet="material-icons-outlined"
                (click)="openDeleteFindingConfirmationModal(finding)"
                >delete</mat-icon
              >
              <ng-container *ngIf="findingsTabSelection === findingStatus.ALL">
                <div
                  class="approved"
                  (click)="getFindingStatus(i, findingStatus.APPROVED)"
                  [ngClass]="{
                    'finding-status-active': !finding?.review_finding_status && !editingStatus[i],
                    'finding-approve-active':
                      finding?.actionStatus[0] ||
                      finding?.review_finding_status?.name === findingStatus.APPROVED,
                    'inactive-btn': !finding?.actionStatus[0] && finding?.review_finding_status
                  }"
                >
                  <app-svg
                    *ngIf="!token"
                    [svgPath]="'../../../../assets/images/noun-check.svg'"
                    title="Approve"
                    placement="top"
                    (click)="saveFinding(finding, findingStatus.APPROVED)"
                    [renderSvg]="true"
                    [approve]="true"
                  ></app-svg>
                </div>
                <div
                  class="reject"
                  (click)="getFindingStatus(i, findingStatus.REJECTED)"
                  [ngClass]="{
                    'finding-status-active': !finding?.review_finding_status && !editingStatus[i],
                    'finding-reject-active':
                      finding?.actionStatus[1] ||
                      finding?.review_finding_status?.name === findingStatus.REJECTED,
                    'inactive-btn': !finding?.actionStatus[1] && finding?.review_finding_status
                  }"
                >
                  <app-svg
                    *ngIf="!token"
                    [svgPath]="'../../../../assets/images/noun-reject.svg'"
                    title="Reject"
                    placement="top"
                    (click)="editFinding(i, findingStatus.REJECTED, findingStatus.COMPLETED)"
                    [renderSvg]="true"
                    [reject]="true"
                  ></app-svg>
                </div>
                <div
                  class="not-applicable"
                  (click)="getFindingStatus(i, findingStatus.NOT_APPLICABLE)"
                  [ngClass]="{
                    'finding-status-active': !finding?.review_finding_status && !editingStatus[i],
                    'finding-applicable-active':
                      finding?.actionStatus[2] ||
                      finding?.review_finding_status?.name === findingStatus.NOT_APPLICABLE,
                    'inactive-btn': !finding?.actionStatus[2] && finding?.review_finding_status
                  }"
                >
                  <app-svg
                    *ngIf="!token"
                    [svgPath]="'../../../../assets/images/noun-applicable.svg'"
                    title="Not Applicable"
                    placement="top"
                    (click)="editFinding(i, findingStatus.NOT_APPLICABLE, findingStatus.COMPLETED)"
                    [renderSvg]="true"
                    [notApplicable]="true"
                  ></app-svg>
                </div>
              </ng-container>

              <ng-container *ngIf="findingsTabSelection === findingStatus.RESOLVED">
                <div
                  class="approved"
                  (click)="getFindingStatus(i, findingStatus.APPROVED)"
                  [ngClass]="{
                    'finding-status-active':
                      finding?.actionStatus[0] ||
                      (selectedStatus === findingStatus.APPROVED && finding?.actionStatus[0]),
                    'finding-approve-active': finding?.actionStatus[0],
                    'inactive-btn': !finding?.actionStatus[0]
                  }"
                >
                  <app-svg
                    *ngIf="!token"
                    [svgPath]="'../../../../assets/images/noun-check.svg'"
                    title="Approve"
                    placement="top"
                    (click)="saveFinding(finding, findingStatus.APPROVED)"
                    [renderSvg]="true"
                    [approve]="true"
                  ></app-svg>
                </div>
                <div
                  class="reject"
                  (click)="getFindingStatus(i, findingStatus.REJECTED)"
                  [ngClass]="{
                    'finding-status-active':
                      finding?.actionStatus[1] ||
                      (selectedStatus === findingStatus.REJECTED && finding?.actionStatus[1]),
                    'finding-reject-active': finding?.actionStatus[1],
                    'inactive-btn': !finding?.actionStatus[1]
                  }"
                >
                  <app-svg
                    *ngIf="!token"
                    [svgPath]="'../../../../assets/images/noun-reject.svg'"
                    title="Reject"
                    placement="top"
                    (click)="editFinding(i, findingStatus.REJECTED, findingStatus.COMPLETED)"
                    [renderSvg]="true"
                    [reject]="true"
                  ></app-svg>
                </div>
                <div
                  class="not-applicable"
                  (click)="getFindingStatus(i, findingStatus.NOT_APPLICABLE)"
                  [ngClass]="{
                    'finding-status-active':
                      finding?.actionStatus[2] ||
                      (selectedStatus === findingStatus.NOT_APPLICABLE && finding?.actionStatus[2]),
                    'finding-applicable-active': finding?.actionStatus[2],
                    'inactive-btn': !finding?.actionStatus[2]
                  }"
                >
                  <app-svg
                    *ngIf="!token"
                    [svgPath]="'../../../../assets/images/noun-applicable.svg'"
                    title="Not Applicable"
                    placement="top"
                    (click)="editFinding(i, findingStatus.NOT_APPLICABLE, findingStatus.COMPLETED)"
                    [renderSvg]="true"
                    [notApplicable]="true"
                  ></app-svg>
                </div>
                <mat-icon
                  *ngIf="!token"
                  fontSet="material-icons-outlined"
                  (click)="editFinding(i, selectedStatus, findingStatus.EDIT)"
                  >create</mat-icon
                >
              </ng-container>

              <ng-container *ngIf="findingsTabSelection === findingStatus.UN_RESOLVED">
                <div
                  class="approved finding-status-active"
                  (click)="getFindingStatus(i, findingStatus.APPROVED)"
                  [ngClass]="{
                    'finding-approve-active': finding?.actionStatus[0],
                    'inactive-btn': !finding?.actionStatus[0] && editingStatus[i]
                  }"
                >
                  <app-svg
                    *ngIf="!token"
                    [svgPath]="'../../../../assets/images/noun-check.svg'"
                    title="Approve"
                    placement="top"
                    (click)="saveFinding(finding, findingStatus.APPROVED)"
                    [renderSvg]="true"
                    [approve]="true"
                  ></app-svg>
                </div>
                <div
                  class="reject finding-status-active"
                  (click)="getFindingStatus(i, findingStatus.REJECTED)"
                  [ngClass]="{
                    'finding-reject-active': finding?.actionStatus[1],
                    'inactive-btn': !finding?.actionStatus[1] && editingStatus[i]
                  }"
                >
                  <app-svg
                    *ngIf="!token"
                    [svgPath]="'../../../../assets/images/noun-reject.svg'"
                    title="Reject"
                    placement="top"
                    (click)="editFinding(i, findingStatus.REJECTED, findingStatus.COMPLETED)"
                    [renderSvg]="true"
                    [reject]="true"
                  ></app-svg>
                </div>
                <div
                  class="not-applicable finding-status-active"
                  (click)="getFindingStatus(i, findingStatus.NOT_APPLICABLE)"
                  [ngClass]="{
                    'finding-applicable-active': finding?.actionStatus[2],
                    'inactive-btn': !finding?.actionStatus[2] && editingStatus[i]
                  }"
                >
                  <app-svg
                    *ngIf="!token"
                    [svgPath]="'../../../../assets/images/noun-applicable.svg'"
                    title="Not Applicable"
                    placement="top"
                    (click)="editFinding(i, findingStatus.NOT_APPLICABLE, findingStatus.COMPLETED)"
                    [renderSvg]="true"
                    [notApplicable]="true"
                  ></app-svg>
                </div>
              </ng-container>
              <mat-icon fontSet="material-icons-outlined" *ngIf="revisionRequested"
                >drag_indicator</mat-icon
              >
            </div>
          </div>
          <div class="findings-view__card-body">
            <p class="findings-view__word text-format">{{ finding?.finding_text1 }}</p>
            <div class="findings-view__add" *ngIf="editingStatus[i]">
              <mat-form-field appearance="outline" subscriptSizing="dynamic">
                <mat-label>Add Comment</mat-label>
                <textarea
                  matInput
                  rows="4"
                  placeholder="Please fill out this field"
                  class="findings-view__textareaaaa"
                  formControlName="comment"
                  (focusout)="removeSpaces(i)"
                ></textarea>
              </mat-form-field>
              <div class="findings-view__btns-wrap">
                <button class="button-outline" (click)="cancelFinding(i)">Cancel</button>
                <button
                  class="btn-filled"
                  type="submit"
                  [disabled]="!findingsFormArray[i].valid"
                  (click)="saveFinding(finding, selectedStatus, true)"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <p class="text-center" *ngIf="!filteredFindings?.length && resolvedFindings !== totalFindings">
      {{ message.NoResultsFound }}
    </p>
  </div>
</div>
