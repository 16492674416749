<div class="pdf" [ngClass]="{'slide-active': expendInfo}">
  <div class="pdf__header" #container (scroll)="detectActiveSection()">
    <div class="tabContent" #element1>
      <h4>Order Information</h4>
      <mat-grid-list [cols]="isTabletView ? 2 : 3" rowHeight="50px">
        <mat-grid-tile ngClass="pdf-column">
          <mat-icon fontSet="material-icons-outlined">update</mat-icon>
          <p><b>Order Created:</b> {{orderReviewHeaderData.createdDate | date:'shortDate'}}</p>
        </mat-grid-tile>
        <mat-grid-tile ngClass="pdf-column">
          <mat-icon fontSet="material-icons-outlined">date_range</mat-icon>
          <p><b>Due:</b> {{orderReviewHeaderData.dueDate | date:'shortDate'}}</p>
        </mat-grid-tile>
        <mat-grid-tile ngClass="pdf-column">
          <img src="../../../../assets/images/file-document-refresh-outline.svg" alt="">
          <p><b>No. of Revisions:</b> {{orderReviewHeaderData.revisionNo}}</p>
        </mat-grid-tile>
        <mat-grid-tile ngClass="pdf-column">
          <img src="../../../../assets/images/account-details-outline.svg" alt="">
          <p><b>Reviewer:</b> {{orderReviewHeaderData.reviewer}}</p>
        </mat-grid-tile>
        <mat-grid-tile ngClass="pdf-column">
          <mat-icon fontSet="material-icons-outlined">how_to_reg</mat-icon>
          <p><b>Appraiser:</b> {{orderReviewHeaderData.vendor}}</p>
        </mat-grid-tile>
        <mat-grid-tile ngClass="pdf-column">
          <img src="../../../../assets/images/outline-textbox.svg" alt="">
          <p><b>Order Purpose Type:</b> {{orderReviewHeaderData.purposeType}}</p>
        </mat-grid-tile>
        <mat-grid-tile ngClass="pdf-column">
          <img src="../../../../assets/images/user.svg" alt="">
          <p><b>Borrower:</b> {{orderReviewHeaderData.borrowerName}}</p>
        </mat-grid-tile>
        <mat-grid-tile ngClass="pdf-column">
          <img src="../../../../assets/images/outline-textbox.svg" alt="">
          <p><b>Product Name:</b> {{orderReviewHeaderData.product}}</p>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
    <div class="tabContent" #element2>
      <h4>Property Information</h4>
      <mat-grid-list [cols]="isTabletView ? 2 : 4" rowHeight="50px">
        <mat-grid-tile ngClass="pdf-column">
          <img src="../../../../assets/images/loan.svg" alt="">
          <p><b>Loan Type:</b> {{orderReviewHeaderData.loanType}}</p>
        </mat-grid-tile>
        <mat-grid-tile ngClass="pdf-column">
          <img src="../../../../assets/images/briefcase.svg" alt="">
          <p><b>FHA No.:</b> {{orderReviewHeaderData.fhaCaseNo}}</p>
        </mat-grid-tile>
        <mat-grid-tile ngClass="pdf-column" [colspan]="2" style="overflow: visible;">
          <mat-icon fontSet="material-icons-outlined">location_on</mat-icon>
          <p><b>Address:</b> {{orderReviewHeaderData.address}}</p>
          <div *ngIf="verifiedAddress"><img *ngIf="addressMsgImg" [src]="addressMsgImg" alt="Info" [nan-tooltip]="addressMsg" placement="top"></div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
    <div class="tabContent" #element3>

    <h4>Client Information</h4>
    <mat-grid-list [cols]="isTabletView ? 2 : 3" rowHeight="50px">
      <mat-grid-tile ngClass="pdf-column">
        <mat-icon fontSet="material-icons-outlined">account_circle</mat-icon>
        <p><b>Client: </b></p>
        <p class="client-name" [matTooltip]="userConfig.checkPermissions(permission.VIEW_CLIENTS) && clientDetail ? clientDetail.company_name : orderReviewHeaderData.client">
          <span  *ngIf="!userConfig.checkPermissions(permission.VIEW_CLIENTS)"> {{orderReviewHeaderData.client}} </span>
          <app-client-smart-link  *ngIf="userConfig.checkPermissions(permission.VIEW_CLIENTS) && clientDetail" [clientId]="clientDetail.id" [clientName]="clientDetail.company_name" [hoverView]="true"></app-client-smart-link>
        </p>
      </mat-grid-tile>
      <mat-grid-tile ngClass="pdf-column" [colspan]="2" style="overflow: visible;">
        <mat-icon fontSet="material-icons-outlined">location_on</mat-icon>
        <p><b>Client Address:</b> {{orderReviewHeaderData.clientAddress}}</p>
      </mat-grid-tile>

      <mat-grid-tile ngClass="pdf-column">
        <img src="../../../../assets/images/client-report.svg" alt="">
        <p><b>COR: </b></p>
        <p class="client-name" [matTooltip]="userConfig.checkPermissions(permission.VIEW_CLIENTS) && clientOnReportDetail ? clientOnReportDetail.company_name : orderReviewHeaderData.clientOnReport">
          <span  *ngIf="!userConfig.checkPermissions(permission.VIEW_CLIENTS)"> {{orderReviewHeaderData.clientOnReport}} </span>
          <app-client-smart-link  *ngIf="userConfig.checkPermissions(permission.VIEW_CLIENTS) && clientOnReportDetail" [clientId]="clientOnReportDetail.id" [clientName]="clientOnReportDetail.company_name" [hoverView]="true"></app-client-smart-link>
        </p>
      </mat-grid-tile>
      <mat-grid-tile ngClass="pdf-column" [colspan]="2" style="overflow: visible;">
        <mat-icon fontSet="material-icons-outlined">location_on</mat-icon>
        <p><b>COR Address:</b> {{orderReviewHeaderData.clientOnReportAddress}}</p>
      </mat-grid-tile>
    </mat-grid-list>
    </div>
    <div class="tabContent" #element4>
      <h4>Integration Information</h4>
      <mat-grid-list [cols]="isTabletView ? 2 : 4" rowHeight="50px">
        <mat-grid-tile ngClass="pdf-column">
          <img [src]="orderReviewHeaderData.isEADactive ? '../../../../assets/images/circle-check.svg' : '../../../../assets/images/minus-circle.svg'" alt="">
          <p><b>EAD:</b> {{orderReviewHeaderData.isEADactive ? 'Activated' : 'Deactivated'}}</p>
        </mat-grid-tile>
        <mat-grid-tile ngClass="pdf-column">
          <img [src]="orderReviewHeaderData.isUCDPactive ? '../../../../assets/images/circle-check.svg' : '../../../../assets/images/minus-circle.svg'" alt="">
          <p><b>UCDP:</b> {{orderReviewHeaderData.isUCDPactive ? 'Activated' : 'Deactivated'}}</p>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
  <div class="bullets-wrapper">
    <div [ngClass]="{'scroll-active': activeSection === 1}" (click)="scrollToElement(1)" title="Order Information"></div>
    <div [ngClass]="{'scroll-active': activeSection === 2}" (click)="scrollToElement(2)" title="Property Information"></div>
    <div [ngClass]="{'scroll-active': activeSection === 3}" (click)="scrollToElement(3)" title="Client Information"></div>
    <div [ngClass]="{'scroll-active': activeSection === 4}" (click)="scrollToElement(4)" title="Integation Information"></div>
  </div>
</div>
