import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { message } from 'src/app/shared/constants/alerts_messages';
import { OrderService } from '../../order.service';
import { ToastrService } from 'ngx-toastr';
import { generalErrorMessage } from 'src/app/shared/errors/error-messages';

@Component({
  selector: 'app-order-completed-modal',
  templateUrl: './order-completed-modal.component.html',
  styleUrls: ['./order-completed-modal.component.scss']
})
export class OrderCompletedModalComponent {
  heading: string;
  message: string;
  actionHeading: string;

  constructor(public dialogRef: MatDialogRef<OrderCompletedModalComponent>,
    public router: Router, @Inject(MAT_DIALOG_DATA) public data: any,
    private orderService: OrderService, private toastr: ToastrService) {
    this.heading = data.heading;
    this.message = data.contentData;
    this.actionHeading = data.actionHeading;
  }

  navigateToPreview(): void {
    this.router.navigateByUrl(`orders/order-review/${this.data.orderId}?reviewId=${this.data.reviewId}&isPreview=true`);
    this.dialogRef.close();
  }

  archiveOrder(): void {
    this.orderService.updateOrders(this.data.orderId, 'ARCHIVED', '').subscribe((response) => {
      if (response) {
        this.toastr.success(message.archivedOrder);
        this.dialogRef.close(response);
      }
    },
      (error) => {
        this.toastr.error(error?.error?.detail && !Array.isArray(error.error.detail) ? error?.error?.detail : generalErrorMessage);
      });
  }

  submit(): void {
    if (this.heading === 'Order Completed') {
      this.navigateToPreview();
    } else if (this.heading === 'Archive Order') {
      this.archiveOrder();
    } else {
      this.dialogRef.close(true);
    }
  }

  close(): void {
    if (this.heading === 'Order Completed') {
      this.dialogRef.close();
      this.router.navigateByUrl(`orders`);
    } else {
      this.dialogRef.close();
    }
  }

}
